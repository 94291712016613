
























import {Component, Mixins} from "vue-property-decorator";
import XButton from "../SimpleButton.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: {
    XButton,
  },
})
class PasswordRecoveryConfirm extends Mixins(AppApiMixin) {
  error = "";
  success = "";

  public mounted() {
    this.error = "";
    this.success = "";
    const email = String(this.$route.query.email || "");
    const guid = String(this.$route.query.guid || "");
    this.recoveryPasswordConfirm(email, guid)
        .then((_) => {
          this.success = "Письмо с паролем отправлено на электронную почту " + email;
        })
        .catch((e) => {
          this.error = e;
        });
  }
  private openSignInPage(): void {
    this.$router.push({ name: "signIn" });
  }
}

export default PasswordRecoveryConfirm;
