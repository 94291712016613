






























































































import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";
import Page from "@/components/for-page-grid/Page.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import XForm from "@/components/SimpleForm.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import { validationMixin } from "vuelidate";
import { appealChatForm } from "@/assets/scripts/form-fields/main";
import { AppealChatMessages, AppealChatFiles } from "@/models/appeals";
import { OrganizationAgent } from "@/models/organization";
import { appealsAcceptedExtensions } from "@/assets/scripts/utils";
import XAppealChatMessage from "@/components/for-appeals/AppealChatMessage.vue";

const { values, attrs, validations, errors } = appealChatForm;

@Component({
  components: {
    Page,
    XForm,
    XNotice,
    XAppealChatMessage
  },
  computed: {
    ...errors,
    ...mapGetters({
      contractId: "contract/id",
      appealChat_get: "appeals/appealHistory",
      currentAppealInfo: "appeals/getCurrentAppealInfo",
      orgContactAgent: "organization/agentList",
      unreadAppealsCount: "appeals/unreadAppealsCount"
    }),
  },
  methods: {
    ...mapMutations("appeals", {
      setUnreadCounterValue: "setUnreadCounterValue",
    }),
    ...mapMutations("sidebar", {
      setCounterValue: "setCounterValue",
    }),
  },
  mixins: [validationMixin],
  validations,
})

class AppealChat extends Mixins(AppApiMixin, XForm) {
  [x: string]: any;

  @Prop({ default: false }) readonly show!: boolean;

  values = values
  defaultValues: {[x: string]: any} = {}
  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  useSuccessAlert = false;
  submitSuccess = '';
  submitErrorText = '';
  submitSuccessText = '';

  appealsChatList: AppealChatMessages[] = [];

  sendAuthorStr = '';

  /**
   * получить список сообщений и список файлов в сообщениях
   */
  getChatList() {
    this.getAppealFiles({
      contractId: this.contractId,
      appealId: parseInt(this.currentAppealInfo[0]['appealId'])
    }, (appealsChatListFiles: AppealChatFiles[]) => {
      this.getAppealHistory({
        contractId: this.contractId,
        appealId: parseInt(this.currentAppealInfo[0]['appealId'])
      },(appealsChatList: AppealChatMessages[]) => {
        const arr = appealsChatListFiles.map((appealsChatFile) => {
          return {
            'file': appealsChatFile['имя файла'],
            'fileId': appealsChatFile['$номерЗаписи'],
          };
        });
        appealsChatList = appealsChatList.map((appealsChat) => {
          Object.assign(appealsChat, arr.find((appealsChatFile) =>
              appealsChat['содержание'].includes(appealsChatFile['file'])
          ))
          return appealsChat
        });
        this.appealsChatList = appealsChatList;
      });
    });
  }

  /**
   * Обновить список непрочитанных
   */
  getUnreadCount() {
    let end_check = Array.from(this.unreadAppealsCount).find(
        item => item['$номерЗаписи'] == this.currentAppealInfo[0]['appealId']
    );
    if (end_check) {
      this.resetUnreadAppeal(
          this.currentAppealInfo[0]['appealId'],
          (data) => {
            if (data && data.data.result) {
              let fin_mas = Array.from(
                  this.unreadAppealsCount).filter(item => item['$номерЗаписи'] != this.currentAppealInfo[0]['appealId']
              );
              if (fin_mas.length !=0) {
                this.setUnreadCounterValue(fin_mas);
                this.setCounterValue(fin_mas.length);
              } else {
                this.setUnreadCounterValue([]);
                this.setCounterValue(0);
              }
              this.$emit('updateCounter');
            }
          }
      );
    }
  }

  /**
   * Получения данных о заявителе
   */
  getAuthor() {
    if (Array.from(this.orgContactAgent).length > 0) {
      Array.from(this.orgContactAgent).map((item: OrganizationAgent) => {
        this.sendAuthorStr += item.фио + ': ' + item.должность + ' ';
      });
    } else {
      this.sendAuthorStr = this.nameOrg;
    }
  }

  mounted() {
    this.getAuthor();
    this.getUnreadCount();
    this.getChatList();
  }

  created() {
    this.values = {...this.values, file: null};
    this.defaultValues = {...this.values, file: null};
  }

  /**
   * Автоматически расширяем поле ввода сообщения
   */
  rowsCount(): Number {
    return Math.max((this.values['message'] || '').split("\n").length, 2);
  }

  /**
   * если вызвали обновление списка сообщений
   */
  @Emit('updateChatTable')
  public appealsChatUpdate() {
    this.getChatList();
  }

  public log(...data: any) {
    data.forEach((m:any) => console.log(m))
  }

  /**
   * отправка сообщения
   */
  async submitForm() {
    this.useSuccessAlert = false
    this.useSubmitButtonLoading = true
    var canSend = true;

    let formData = new FormData();
    for (let key in this.values) {
      if (key == 'file') {
        // если есть файлы, прикрепляем
        if (this.values[key]) {
          if((this.values[key] || []).length >= 20) {
            canSend = false;
            this.submitErrorText = 'Загружаемых файлов больше 20, вы загрузили: ' +
              (this.values[key] || []).length;
          }
          for (let i = 0; i < (this.values[key] || []).length; i++) {
            var temp = this.values[key][i];
            if (temp.size >= 3145728) {
              canSend = false;
              this.submitErrorText = 'Загруженный файл "' + this.values[key][i]['name']
                + '" превышает допустимый размер 3МБ, ваш: ' +
                (temp.size / (1024 * 1024)).toFixed(2) + 'МБ';
            }
            if (temp.size == 0) {
              canSend = false;
              this.submitErrorText = 'Загруженный файл пустой';
            }
            if (this.getAppealsAcceptedExtensions().indexOf(temp['name'].split('.').pop()) < 0) {
              canSend = false;
              this.submitErrorText = 'Загруженный файл "' + this.values[key][i]['name']
                + '" имеет неподдерживаемый формат: ' + temp['name'].split('.').pop();
            }
            if (canSend == true) {
              formData.append(key + '[' + i + ']', this.values[key][i]);
            }
          }
        }
      } else {
        formData.append(key, this.values[key]);
      }
    }
    formData.append('contractId', this.contractId);
    formData.append('appealId', this.currentAppealInfo[0]['appealId']);
    formData.append('author', this.sendAuthorStr);

    if(canSend == true) {
      try {
        const data = await this.sendAppealMessage(formData);
        if (data && data.result) {
          this.submitSuccess = 'Сообщение успешно отправлено';
          this.submitSuccessText = 'Сообщение успешно отправлено';
          this.useSuccessAlert = true;

          this.values = {...this.defaultValues};

          // обновить список сообщений
          this.getChatList();
        }
      } catch (e) {
        console.log('error', e)
      }
    }
    this.submitSuccess = '';
    this.useSubmitButtonLoading = false
  }

  getAppealsAcceptedExtensions() { return appealsAcceptedExtensions() }
  getAppealsAcceptedExtensionsText() { return 'Доступны форматы: ' + appealsAcceptedExtensions() }
}

export default AppealChat;
