













































import { Component, Mixins } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import XForm from "../SimpleForm.vue";
import XButton from "../SimpleButton.vue";
import { passwordRecoveryForm } from "@/assets/scripts/form-fields/main";
const { values, attrs, validations, errors } = passwordRecoveryForm;
import AppApiMixin from "../mixins/AppApi.vue";

@Component({
  components: {
    XForm,
    XButton,
  },
  mixins: [validationMixin],
  computed: errors,
  validations,
})
class PasswordRecoveryForm extends Mixins(XForm, AppApiMixin) {
  values = values;
  allFieldAttrs = attrs;
  submitSuccess = "";
  sent = false;

  useSubmitButtonLoading = false;

  private openSignInPage(): void {
    this.redirectToPage("signIn");
  }

  private async submitForm() {
    this.submitSuccess = "";
    const { email } = this.getValues();
    this.recoveryPassword(email)
        .then((_) => {
          this.submitSuccess = "Письмо с инструкциями отправлено на указанную электронную почту";
          this.sent = true;
        })
        .catch((e) => {
          this.submitError = e;
        })
        .finally(() => {
          this.useSubmitButtonLoading = false;
        });
  }
}

export default PasswordRecoveryForm;
